import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import ScrollTop from '../../components/ScrollTop';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { withStyles } from "@material-ui/core/styles";
import Loading from '../../components/Loading';
import moment from 'moment';
import ButtonMore from '../../components/Button';

const Reviews = (props) => {
    const { classes, theme, review, orders } = props;

    const sortRewiev = () => {
      if (orders.length > 0) {
        return orders.sort(function (a, b) {
          let dateFirst = a.date;
          let dateSecond = b.date;
  
          a = a.CallCenter+a.Courier+a.SushiQuality;
          b = b.CallCenter+b.Courier+b.SushiQuality;
  
          if(props.typeSort === 'highGrade'){
            return b - a
          } else if (props.typeSort === 'lowGrade'){
            return a - b
          } else if (props.typeSort === 'newest'){
            a = moment(dateFirst);
            b = moment(dateSecond);
            return b - a
          }
        });
      } else {
        return null;
      }
    }

    return(
           <Grid direction={'row'}  container alignItems={'center'}>
             <CssBaseline />
            {props.loadStatus ? <div className={classes.load}>
              <Loading color='#326dff'/>
            </div> : <Grid spacing={3} container className={classes.reviewContaoner}>
            {!sortRewiev() &&  <Typography className={classes.reviews}>У Вас еще нету отзывов!</Typography>}
            {sortRewiev() && sortRewiev().map((item, index) => {
                return(
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index} id="back-to-top-anchor">
                    <div className={classes.row}>
                    <div className={classes.wrapper}>
                        <span>Дата:</span> {item.date}
                    </div>
                    <div className={classes.wrapper}>
                        <span>Номер телефона:</span> {item.phone}
                    </div>
                    <div className={classes.wrapper}>
                        <span>Качество товара:</span> {item.SushiQuality}
                    </div>
                    <div className={classes.wrapper}>
                        <span>Работа Call-центра:</span> {item.CallCenter}
                    </div>
                    <div className={classes.wrapper}>
                        <span>Качество доставки:</span> {item.Courier}
                    </div>
                    <div className={classes.wrapper}>
                        <span>NPS:</span> {item.NPS}
                    </div>
                    <div className={classes.wrapper}>
                        <span>Комментарий:</span> {item.Suggestions}
                    </div>
                    </div>
                </Grid>
                )
            })}
            <ScrollTop {...props} id={'#back-to-top-anchor'}>
              <Fab className={classes.arrowUp} color="primary" size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon />
              </Fab>
            </ScrollTop>
            {props.showMore && <div className={classes.btContainer}>
            <ButtonMore handleClick={props.getMoreReview} 
                  load={props.loadButton} size={22} text={'Показать ещё 7 дней'}/>
            </div>}
        </Grid>}
        
      </Grid>
    )
}

const styles = theme => ({
  selectContainer: {
    maxwWidth: 150,
    marginLeft: 5,
    '& .MuiInput-underline:before': {
       display: 'none',
      },
    '& .MuiInput-underline:after': {
        display: 'none',
    },
    '& .MuiSelect-select': {
        paddingTop: 0,
        paddingBottom: 0,
    },
    '& .MuiSelect-select:focus': {
        backgroundColor: '#fafafa'
    },
  },
  select: {
    fontSize: 14,
    padding: 0,
  },
  title:{
    fontSize: 14,
  },
  sortContainer: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      backgroundColor: '#fff',
      padding: 15,
      borderRadius: 5,
      position: 'sticky',
      top: 80,
      boxShadow: '0 10px 18px rgba(0,0,0,0.08)'
  },
  buttonContainer: {
      flexGrow: '1',
      display: 'flex',
      alignItems: 'center',
      '& .MuiButton-root': {
        fontSize: 13,
      },
      '& .Mui-disabled': {
        color: '#326dff'
      }
  },
  button: {
      padding: '0px 5px',
      display: 'flex',
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
  },
  reviewContaoner: {
    padding: '20px 0px',
    background: 'rgba(241,243,244, .5)',
    margin: 0,
  },
  row: {
    display: 'flex',
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
    alignContent: 'flex-start',
    flexWrap: 'wrap',
    padding: 15,
    boxSizing: 'border-box',
    [theme.breakpoints.down("600")]: {
      // width: 'calc(100% - 30px)',
      // height: 'calc(100% - 30px)',
    }
  },
  wrapper: {
    width: '100%',
    paddingBottom: 10,
    fontSize: 16,
    fontWeight: 400,
    '& span': {
      fontWeight: 600,
    }
  },
  load: {
    width: '100%',
    padding: 15,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  btContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  reviews: {
    textAlign: 'center',
    margin: '1rem 0',
    width: '100%',
  },
  arrowUp: {
    background: '#326dff',
  }
});

export default withStyles(styles, { withTheme: true })(Reviews);