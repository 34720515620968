import {combineReducers, createStore, applyMiddleware} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {reducer as formReducer} from 'redux-form';

import auth from './auth';
import shops from './shops';

let reducer = combineReducers({
    form: formReducer,
    auth,
    shops,
})

const store = createStore(reducer, applyMiddleware(thunkMiddleware));

export default store;