import React, { useState, useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import Button from '../../components/Button';
import CityFields from './CityFields';
import ModalComponent from '../../components/ModalComponent';

let CityForm = ({ initialValues, saveCity, handleSubmit }) => {
    const classes = useStyles();

    const [openModal, setOpenModal] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const [dataCityForm, setDataCityForm] = useState(null);
    const isMin600 = useMediaQuery('(max-width: 599px)');
    const isMax600 = useMediaQuery('(min-width: 600px)');

    const handleClose = () => {
        setOpenModal(false);
        setIsSubmit(false);
    };

    const handleClickSubmit = () => {
        setOpenModal(false);
        setIsSubmit(true);
    };

    const handleCityForm = (data) => {
        setOpenModal(true); 
            if (data) {
                setDataCityForm(data);
            }
    }

    useEffect(() => {
        if (isSubmit && !openModal) {
           console.log('Вы подтвердили');

            saveCity(dataCityForm);
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmit, openModal]);

    console.log('isMin600', isMin600);
    console.log('isMax600', isMax600);

    return (
        <form onSubmit={handleSubmit} autoComplete='off' initialValues={initialValues}>
            <ModalComponent
                title="Подтвердите действие!"
                description={`Вы подтверждаете сохранение изменений`} 
                open={openModal} 
                handleClose={handleClose} 
                handleClickSubmit={handleClickSubmit} />
            <CityFields 
                initialValues={initialValues} 
                className={classes.cityFields}
            />
            <div className={classes.buttonWrapper}>
                <Button fontSize={22} color={'#fff'} className={classes.button} 
                    text="Сохранить"
                    handleClick={handleSubmit(data => handleCityForm(data))} />
            </div>
        </form>
    )
}

const useStyles = makeStyles(theme => ({
    cityFields: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 8,
        padding: '8px 16px 24px 16px',
        border: '1px solid #DADCE0',
        borderRadius: 5,
    },
    buttonWrapper: {
        textAlign: 'center',
    },
    button: {
        marginTop: 30,
        textTransform: 'none',
    }
}));

const mapStateToProps = (state, props) => {
    const data = props.data;

    if (data) {
        const {deliveryLimit, deliveryFreeLimit, delivery} = data;

        let initialValues = {
            delivery,
            deliveryLimit,
            deliveryFreeLimit,
        };

        return { initialValues };
    }

}

CityForm = connect(mapStateToProps, null)(reduxForm({
    form: 'CityForm', 
    enableReinitialize : true
})(CityForm));

export default CityForm