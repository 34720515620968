import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import AddForm from './addCategory';
import ListCategory from './listCategories';
import AddIcon from '@material-ui/icons/Add';
// import Language from './language';

const Categories = (props) => {
    const classes = useStyles();
    const path = props.match.path;

    return(
        <div className={classes.categoyContainer}>
            {/* {props.modal ? 
                <AddForm
                    deliveryTerminals={props.deliveryTerminals}
                    handleCheckBox={props.handleCheckBox}
                    language={props.defaultLanguage}
                    languages={props.languages}
                    onSubmit={props.onSubmit} 
                    statusModal={props.statusModal} /> : ''} */}
            {/* <Language   languageModal={props.languageModal} 
                        language={props.language}
                        languages={props.languages}
                        statusLanguageModal={props.statusLanguageModal}
                        updateLanguage={props.updateLanguage}/> */}
            <ListCategory categories={props.categories}
                          deliveryTerminals={props.deliveryTerminals}
                          handleCheckBox={props.handleCheckBox}
                          defaultLanguage={props.defaultLanguage}
                          hiddenCategories={props.hiddenCategories}
                          defaultHiddenCategories={props.defaultHiddenCategories}
                          hiddenCategory={props.hiddenCategory}
                          languages={props.languages}
                          language={props.defaultLanguage}
                          activeCategories={props.activeCategories}
                          deleteCategory={props.deleteCategory}
                          editCategory={props.editCategory} />
            <Link to={`${path}/addCategory`} className={classes.openModal}>
                <AddIcon className={classes.icon} />
            </Link>
        </div>
    )
}

const useStyles = makeStyles({
    container: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexWrap: 'wrap',
        padding: '20px 0px',
    },
    icon: {
        width: 35,
        height: 35,
        padding: 5,
        background: '#326dff',
        color: 'white',
        borderRadius: '50%',
        position: 'fixed',
        bottom: 15,
        right: 15,
        backgroundImage: 'url(../../assets/img/plus.svg)',
        backgroundPosition: 'center',
        backgroundSize: 20,
        backgroundRepeat: 'no-repeat',
        opacity: 0.7,
        transition: 'all 0.5s',
        cursor: 'pointer',
        '&:hover': {
            opacity: 1,
        }
    }
});

export default withRouter(Categories);