import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withRouter } from "react-router";
import {updateKeyShop, updateSettings, updateWorktime, updateIsDirtyWorktime, updateWorkingHours} from '../../reducers/shops';
import { withFirebase } from '../../firebase'

import WorkTime from './WorkTimeComponent';

class WorkTimeContainer extends Component{

    constructor(){
        super();

        this.state = {
            checked: true,
            keyShop: null,
            preloader: false,
            orderTimeIntervalDelivery: null,
        }
    }

    componentDidMount(){
        this.props.updateKeyShop(this.props.match.params.city);
        this.setState({
            keyShop: this.props.match.params.city
        })
        this.setState({
            orderTimeIntervalDelivery: this.props.appSettings.orderTimeIntervalDelivery
        })
       this.getWorkingHours();
    }

    getWorkingHours = () => {
        this.props.firebase.bd.ref(`${this.props.login === 'vashlavash' ? '' : this.props.login}/settings/City/${this.props.match.params.city}/workingHours`).once("value", (snapshot) => {
            let workingHours = snapshot.val();
            let data = {};

            if (!workingHours) {
                data = {
                    begin: '0000',
                    end: '0000',
                    message: '',
                    orderTimeIntervalDelivery: 0,
                    orderTimeIntervalPickup: 0,
                }
            }

            if (workingHours) {
                data = {
                    begin: workingHours[0].begin.replace(':', ''),
                    end: workingHours[0].end.replace(':', ''),
                    message: workingHours[0].message,
                    orderTimeIntervalDelivery: workingHours[0].orderTimeIntervalDelivery,
                    orderTimeIntervalPickup: workingHours[0].orderTimeIntervalPickup,
                }
            }

            console.log('workingHours', workingHours);
            
            this.props.updateWorkingHours(data);
            this.setState({ keyShop: this.props.match.params.city });
        });
    }

    updateIsDirty = (data) => {
        const {shops} = this.props;
        const city = shops[this.props.match.params.city];

       if (!city.isDirtyWorktime) {
        this.props.updateIsDirtyWorktime(data);
       }
    }

    saveWorkTime = (formData) => {
        const begin = formData.begin.split('');
        const end = formData.end.split('');

        const data = {
            begin: `${begin[0]}${begin[1]}:${begin[2]}${begin[3]}`,
            end: `${end[0]}${end[1]}:${end[2]}${end[3]}`,
            message: !!formData.message ? formData.message : '',
            orderTimeIntervalDelivery: Number(formData.orderTimeIntervalDelivery),
            orderTimeIntervalPickup: Number(formData.orderTimeIntervalPickup),
        };
        console.log('formData', formData);
        console.log('data', data);

        this.setState({ preloader: true });
        this.props.firebase.bd.ref(`${this.props.login === 'vashlavash' ? '' : this.props.login}/settings/City/${this.props.match.params.city}/workingHours/0`).update(data)
            .then(response => {
                this.setState({ preloader: false });
                this.props.updateWorkingHours(formData);
                console.log('response', response)
            })
            .catch(error => console.log('error', error));
    }

    render() {
        return(
            <>
                <WorkTime 
                    keyShop={this.state.keyShop}
                    preloader={this.state.preloader}
                    getWorkingHours={this.getWorkingHours}
                    saveWorkTime={this.saveWorkTime}
                    cities={this.props.shops}
                    city={this.props.shops[this.props.match.params.city] ? this.props.shops[this.props.match.params.city] : []}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        login: state.auth.login,
        shops: state.shops.shops,
        appSettings: state.shops.appSettings
    }
}

export default connect(mapStateToProps, {updateKeyShop, 
    updateWorktime, updateSettings, updateIsDirtyWorktime, updateWorkingHours})(withRouter(withFirebase(WorkTimeContainer)));
