import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import { makeStyles } from '@material-ui/core';
import inputTextarea from '../../../components/UIform/inputTextarea'
import { createTextMask } from 'redux-form-input-masks';
import ModalComponent from '../../../components/ModalComponent';

import Button from '@material-ui/core/Button'; 
// import Button from '../../../components/Button'; 
import Loading from '../../../components/Loading';

const renderField = ({ 
    input, 
    meta, 
    ...rest 
  }) => {
    //   const inputVal = input.value ? input.value.replace(/:/g, '').replace(/-/g, '').replace(/_/g, '') : null;
    //   const initVal = meta.initial ? meta.initial : null;

    return (
      <div>
          <input
            value={input.value}
            onChange={input.onChange} 
            {...rest} />
            {meta.touched && meta.error && <div>{meta.error}</div>}
      </div>
    )
}

let TableForm = (props) => {
    const classes = useStyles();
    const { handleSubmit, pristine, reset, submitting } = props

    const [openModal, setOpenModal] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [dataTableForm, setDataTableForm] = useState(null);

    const handleClose = () => {
        setOpenModal(false);
        setIsSubmit(false);
    };

    const handleClickSubmit = () => {
        setOpenModal(false);
        setIsSubmit(true);
    };

    const handleTableForm = (data) => {
        setOpenModal(true); 
            if (data) {
                setDataTableForm(data);
            }
    }

    useEffect(() => {
        if (isSubmit && !openModal) {
           console.log('Вы подтвердили');

           props.saveWorkTime(dataTableForm);
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmit, openModal]);

    const myCustomMaskDefinitions = {
        2: {
            regExp: /[0-2]/,
        },
        5: {
            regExp: /[0-5]/,
          },
        9: {
            regExp: /[0-9]/,
          },
      };
    const phoneMask = createTextMask({ 
        pattern: '29:59',
        maskDefinitions: myCustomMaskDefinitions,
    });
     const minuteMask = createTextMask({ 
        pattern: '999',
        maskDefinitions: myCustomMaskDefinitions,
    });
    return (    
        <div className={classes.root}>
            <ModalComponent
                title="Подтвердите действие!"
                description={`Вы подтверждаете сохранение изменений`} 
                open={openModal} 
                handleClose={handleClose} 
                handleClickSubmit={handleClickSubmit} />
            <form onSubmit={props.handleSubmit} initialValues={props.initialValues}>

                <div className={classes.workTimeWrap}>
                    <div className={classes.labelWrap}>
                        <label>Минимальное  заведения</label>
                    </div>
                    <Field 
                        className={classes.calendarField}
                        name="begin"
                        component={renderField}
                        type="text"
                        placeholder="Минимальное время"
                        label="Минимальное время"
                        {...phoneMask}
                    />
                </div>

                <div className={classes.workTimeWrap}>
                    <div className={classes.labelWrap}>
                        <label>Максимальное время работы заведения</label>
                    </div>
                    <Field 
                        className={classes.calendarField}
                        name="end"
                        component={renderField}
                        type="text"
                        placeholder="Максимальное время"
                        label="Максимальное время"
                        {...phoneMask}
                    />
                </div>

                <div className={classes.workTimeWrap}>
                    <div className={classes.labelWrap}>
                        <label>Интервал времени для доставки (мин)</label>
                    </div>
                    <Field 
                        className={classes.calendarField}
                        name="orderTimeIntervalDelivery"
                        component={renderField}
                        type="number"
                        placeholder=""
                        label=""
                    />
                </div>

                <div className={classes.workTimeWrap}>
                    <div className={classes.labelWrap}>
                        <label>Интервал времени для самовывоза (мин)</label>
                    </div>
                    <Field 
                        className={classes.calendarField}
                        name="orderTimeIntervalPickup"
                        component={renderField}
                        type="number"
                        placeholder=""
                        label=""
                    />
                </div>

                <div className={classes.workTimeWrap}>
                    <div className={classes.labelWrap}>
                        <label>Информационное сообщение</label>
                    </div>
                    <Field 
                        className={classes.textAreaField}
                        name="message"
                        component={inputTextarea}
                        type="text"
                        placeholder="Введите текст..."
                        label=""
                    />
                </div>

                <Button 
                    onClick={handleSubmit(data => handleTableForm(data))} 
                    className={classes.button} 
                    disabled={props.preloader ? true : false}
                    variant="contained" 
                    color="primary">
                        {props.preloader ? <Loading size={22} color={'#fff'}/>  : 'Сохранить'}
                </Button>   
            </form>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '24px',
        marginBottom: '0px',
    },
    workTimeWrap: {
        marginBottom: 24,
    },
    labelWrap: {
        display: 'block',
        marginBottom: '8px',
        '& > label': {
            fontSize: 13,
            lineHeight: 1,
            fontWeight: 400,
            color: '#333',
        }
    },
    calendarField: {
        width: 140,
        textAlign: 'center',
        height: 30,
        fontSize: 16,
        lineHeight: '30px',
        border: '1px solid #DADCE0',
        boxSizing: 'border-box',
        borderRadius: 5,
        overflow: 'hidden',
        '& .MuiInput-input': {
            textAlign: 'center',
            padding: '5px 0 7px',
            color: '#476282',
        },
        '& .MuiInput-input:disabled': {
            background: '#f5f5f5',
            border: '1px solid transparent',
        },
        '& .MuiInput-underline:after': {
            content: 'none'
        },
        '& .MuiInput-underline:before': {
            content: 'none'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            content: 'none'
        },
    },
    textAreaField: {
        width: '100%',
        '& > .MuiInputBase-multiline': {
            border: '1px solid #DADCE0',
            borderRadius: 5,
            padding: '12px 6px',
            '&:after': {
                display: 'none'
            },
            '&:before': {
                display: 'none'
            }
        }
    },
    button: {
        background: '#326dff',
      borderRadius: 3,
      border: 0,
      color: 'white',
      height: 45,
      minWidth: 180,
      width: 'auto',
      fontSize: 14,
      marginTop: 10,
      boxShadow: 'none',
      '&:hover': {
        background: '#326dffcf',
        boxShadow: 'none',
      }
    }
}));

// const mapStateToProps = (state) => {
//     let initialValues = {};
//     let city = state.shops.shops[state.shops.keyShop];

//     // console.log('city', city);

//     if (city && city.workingHours) {
//         let time = city.workingHours[0];

//         initialValues.begin = time.begin.replace(':', '');
//         initialValues.end = time.end.replace(':', '');
//         // initialValues.orderTimeLimit = String(time.orderTimeLimit);
//         // initialValues.message = time.message ? time.message : '';
//     }

//     console.log('initialValues', initialValues);

//     // return { initialValues };
// }

TableForm = connect(null, null)(reduxForm({
    form: 'WorkingHours', 
    enableReinitialize : true
})(TableForm));

export default TableForm;