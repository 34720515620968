/* eslint-disable array-callback-return */
import React from 'react';
import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";

import Loading from '../../components/Loading';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DeleteOutlineSharpIcon from '@material-ui/icons/DeleteOutlineSharp';

const TabAppItem = ({ 
    classes, 
    index, 
    handleButtonClick,
    item: { 
        full_name, 
        phone, 
        rating, 
        text_review, 
        categorieName,
    } }) => {
    
    return (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <div className={classes.row}>   
                <div className={classes.itemHeaderContainer}>
                    <span className={classes.itemHeadline}> {full_name}</span>
                    <span className={classes.iconWrapper}>
                        <DeleteOutlineSharpIcon className={classes.iconDelete} onClick={() => handleButtonClick(categorieName)} />
                    </span>
                </div>
                <div className={classes.itemRatingContainer}>
                    <span className={classes.itemHeadline}>Колличество звёзд:</span>
                    <span className={classes.itemRatingContent}>{rating}</span>
                </div>
                <div className={classes.itemRatingContainer}>
                    <span className={classes.itemHeadline}>Номер телефона:</span>
                    <span className={classes.itemRatingContent}>{phone}</span>
                </div>
                <div className={classes.itemCommentContainer}>
                    <span className={classes.itemHeadline}>Комментарий:</span>
                    <p className={classes.itemCommentContent}>{text_review}</p>
                </div>
            </div>
        </Grid>
    )
}

const TapApp = (props) => {
    const classes = useStyle();
    const { review } = props;

    const sortRewiev = () => {
        if (review.length > 0) {
          return review.sort(function (a, b) {
            let dateFirst = a.date;
            let dateSecond = b.date;
    
            a = a.CallCenter+a.Courier+a.SushiQuality;
            b = b.CallCenter+b.Courier+b.SushiQuality;
    
            if(props.typeSort === 'highGrade'){
              return b - a
            } else if (props.typeSort === 'lowGrade'){
              return a - b
            } else if (props.typeSort === 'newest'){
              a = moment(dateFirst);
              b = moment(dateSecond);
              return b - a
            }
          });
        } else {
          return null;
        }
    }

    return (
        <div className={classes.container}>
            {props.loadStatus 
                ? <div className={classes.load}><Loading color='#326dff'/></div> 
                :   <Grid container spacing={3} className={classes.reviewContainer}>
                        {!sortRewiev() &&  <Typography className={classes.reviews}>У Вас еще нету отзывов!</Typography>}
                        {sortRewiev() && sortRewiev().map((item, index) => 
                            <div key={index}>
                                <TabAppItem 
                                    item={item} 
                                    index={index} 
                                    classes={classes}
                                    handleButtonClick={props.handleButtonClick}  />
                            </div>
                        )}
                    </Grid>
                }           
        </div>
    )
}

const useStyle = makeStyles({
    container: {
        // background: 'green',
    },
    row: {
        width: 224,
        height: 'auto',
        background: 'white',
        border: '1px solid #F1F3F4',
        borderRadius: 5,
        marginRight: 16,
        padding: '10px 16px',
        boxSizing: 'border-box',
    },
    itemHeaderContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    itemHeadline: {
        fontSize: 15,
        fontWeight: 'bold',
        lineHeight: '18px',
        color: '#476282',
    },
    iconDelete: {
        position: 'relative',
        top: 2,
        width: 22,
        height: 22,
        color: '#476282',
        cursor: 'pointer',
    },
    itemRatingContainer: {
        marginTop: 16,
    },
    itemRatingContent: {
        display: 'block',
        fontSize: 15,
        lineHeight: '18px',
        color: '#1A1717',
    },
    itemCommentContainer: {
        marginTop: 16,
    },
    itemCommentContent: {
        margin: 0,
    },
    reviewContainer: {
        margin: 0,
    }
});

export default TapApp;