import * as axios from "axios";

const instance = axios.create({
    baseURL: 'http://web.e-admin.com.ua/api/api.php',
})

export const firebaseAPI = {
    getConfig(login){
        // console.log('login', login);
        var body = new FormData;
        // body.append('login', login);
        body.append('login', login);
        return instance.post(`/`, body)
            .then (response => {
                return response.data;
            })
    },
};