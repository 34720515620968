import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
// import {connect} from 'react-redux';

// import sushimaster from './sushimaster.json';
// import yasumaki from './yasumaki.json';

class Firebase {
    constructor(props) {
      let config = props.config;
      //let data = require(`./${login}.json`);
      app.initializeApp(config);
      this.auth = app.auth();
      this.bd = app.database();
      this.storage = app.storage();
      this.deleteSessions = () => {
         app.app().delete();
      }
    }

    doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => {
      this.auth.signOut();
      this.deleteSessions();
    };

 
  }

export default Firebase;