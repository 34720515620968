import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const DisabledTabs = ({ value, setValue, isActiveReviews, isActiveOrderReviews, isActiveApps }) => {
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.container}>
          <Tabs
            value={value}
            className={classes.tabs}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            {isActiveOrderReviews && <Tab className={classes.tabItem} label="Заказы" />}
            {isActiveReviews && <Tab className={classes.tabItem} label="Товары" />}
            {/* {isActiveApps && <Tab className={classes.tabItem} label="Приложение"  />} */}
          </Tabs>
        </div>
      );
}
const useStyles = makeStyles(theme => ({
    container: {
        marginTop: 16,
    },
    tabs: {
        [theme.breakpoints.down("600")]: {
            background: '#F1F3F4',
            border: '1px solid #D6D6D6',
            boxSizing: 'border-box',
            boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.04)',
            borderRadius: 48,
            height: 48,
        },
        '& > .MuiTabs-scroller > .MuiTabs-flexContainer > button > span.MuiTab-wrapper': {
            fontSize: 13,
            lineHeight: '15px',
            textTransform: 'capitalize',
            color: '#476282',
        },
        '& > .MuiTabs-scroller > .MuiTabs-flexContainer > button': {
            borderBottom: '1px solid #ccc',
            [theme.breakpoints.down("600")]: {
                width: '100%',
                maxWidth: 147,
                height: 40,
                borderRadius: 40,
                padding: '0 4px',
                borderBottom: 'none',
            }
        },
        '& > .MuiTabs-scroller > .MuiTabs-flexContainer > button.Mui-selected > span.MuiTab-wrapper': {
            color: '#326DFF',
        },
        '& > .MuiTabs-scroller > .MuiTabs-flexContainer > button.Mui-selected span.MuiTab-wrapper': {
            [theme.breakpoints.down("600")]: {
                height: 40,
                borderRadius: 40,
                background: 'white',
            }
        },
        '& > .MuiTabs-scroller > .MuiTabs-flexContainer': {
            [theme.breakpoints.down("600")]: {
                display: 'flex',
                justifyContent: 'space-between',
                border: 'none',
                position: 'relative',
                top: -1,
            }
        },
        '& > .MuiTabs-scroller > .MuiTabs-indicator': {
            background: '#326DFF',
            [theme.breakpoints.down("600")]: {
                display: 'none',
            }
        },
    },
    tabItem: {
        [theme.breakpoints.down("600")]: {
            // padding: '0 28px',
        }
    }
}));

export default DisabledTabs;