import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  withRouter
} from "react-router-dom";

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import * as ROUTERS from '../../../constants/routes';
import Products from './products';
import Categories from './categories';

const AntTabs = withStyles({
    root: {
      minHeight: 32,
      borderBottom: '1px solid inherit',
    },
    indicator: {
      maxWidth: 24,
      height: 2,
      backgroundColor: '#326DFF',
    },
  })(Tabs);

  const AntTab = withStyles((theme) => ({
    root: {
      color: '#476282',
      minWidth: 30,
      minHeight: 32,
      marginRight: 30,
      fontFamily: 'Poppins, sans-serif',
      fontSize: 16,
      fontWeight: 600,
      textTransform: 'inherit',
      textAlign: 'left',
      padding: 0,
      '&:last-child': {
        marginRight: 0,
      },
      '&:hover': {
        color: '#326DFF',
        opacity: 1,
      },
      '&$selected': {
        color: '#326DFF',
        fontWeight: 600,
        textAlign: 'left',
      },
      '&:focus': {
        color: '#326DFF',
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);

const TabsComponent = (props) => {
    const classes = useStyles();
    const {saleFirstOrder, promoCodes, discontProps, adminPermission} = props;
    const [value, setValue] = React.useState(0);
    const baseUrl = props.match.url;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
      <div className={classes.tabsComponent}>
        <Router>
            <div className={classes.tabHeader}>
              <div className={classes.tabLinks}>
                {true && <NavLink exact to={ROUTERS.SORT_MODE_CATEGORIES} className={classes.tabLink}>Категории</NavLink >}
                {true && <NavLink to={ROUTERS.SORT_MODE_PRODUCTS} className={classes.tabLink}>Товары</NavLink >}
              </div>
            </div>

            <div>
            <Switch>
              {true && <Route exact path={`${baseUrl}/categories`}><Categories /></Route>}
              {true && <Route path={`${baseUrl}/products`}><Products /></Route>}
            </Switch>
          </div>
        </Router>
      </div>
    )
}

const useStyles = makeStyles(theme => ({
    tabsComponent: {

    },
    tabs: {
        marginTop: 20,
        minHeight: 'auto',
    },
    tab: {
        minWidth: 'auto',
        minHeight: 'auto',
        padding: 0,
        paddingRight: 30,
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '28px',
        textTransform: 'inherit',
        color: '#476282',
        '&:last-child': {
            // background: 'red'
        },
        '&.MuiTouchRipple-root': {

        }
    },
    tabHeader: {
        margin: '16px 0 26px 10px',
    },
    discountsAndPromotions: {
       
    },
    tabLinks: {
      display: 'flex',
    },
    tabLink: {
      position: 'relative',
      fontFamily: 'Poppins, sans-serif',
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '28px',
      marginRight: 26,
      textDecoration: 'none',
      color: '#476282',
      '&.active': {
        color: '#326DFF',
        '&::before': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '50%',
          height: 2,
          background: '#326DFF',
        }
      }
    }
}))

export default withRouter(TabsComponent);