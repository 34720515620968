import React, { useEffect } from 'react';
import {reduxForm, reset, Field} from 'redux-form';
import { Link } from 'react-router-dom';
import {Input} from '../../ui/Input';
import {required} from 'redux-form-validators';
import Button from '../../../../components/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '../ui/Select';

import useStyles from './styles';

let EditCategoryForm = (props) => {
    const {
        load,
        language,
        languages,
        category,
        cities,
        isCheckBoxList,
        selectedTerminals,
        boxState,
        handleSubmit,
        handleChange,
        changeSelectOpen,
        changeSelectClose,
        selectedCities,
        setSelectedCities,
    } = props;

    const classes = useStyles();

    const getSelectedCities = () => {
        const address = [];
        let terminals = [];
        const selectedCities = [];
        const spots = category.spots;

        cities.map((city) => {
            address.push(city.address);
            for (let key in city.deliveryTerminals) {
                const terminal = city.deliveryTerminals[key];

                terminals.push({ ...terminal, cityId: city.spot_id });
            }
        });

        for (let key in spots) {
            const spot = spots[key];
            const terminalId = spots[key].spot_id ? spots[key].spot_id : key;
            
            for (let key in terminals) {
                const terminal = terminals[key];

                if (Number(terminalId) === Number(terminal.id) && spot.visible === "1") {
                // if (Number(terminalId) === Number(terminal.id)) {
                    const { address } = cities.find(city => city.spot_id === terminal.cityId);
                   if (!selectedCities.includes(address)) {

                       selectedCities.push(address);
                   } 
                }
            }
        }

        setSelectedCities(selectedCities);
    }

    const getInitialValues = () => {
        let initialize = {};

        languages.map(({ key }) => {
            for (let name in category.name) {
                if (key === name) {
                    initialize[key] = category.name[key];
                }
            }
        });
        props.initialize(initialize);
    }

    const formatName = (item) => {
        let name = item.label.split(' ')[2];
        name = name.replace('ом', 'ий');
        return name.charAt(0).toUpperCase() + name.slice(1);
    }

    useEffect(() => {
        if (category) getSelectedCities();
        if (category && languages) getInitialValues();
    }, [category, languages]);
    

    const isOneCity = cities.length === 1;
    const isOneTerminal = selectedTerminals.length === 1;

    // console.log('boxState', boxState);
    // console.log('cities', cities);
    // console.log('isOneTerminal', isOneTerminal);
    // console.log('selectedTerminals', selectedTerminals);

    return (
        <form className={classes.formContainer}  autoComplete='off' onSubmit={handleSubmit}>
            <Link to={'/page/menu/products/'} className={classes.close}>
                <ArrowBackIcon className={classes.closeIcon} />
            </Link>
            <span className={classes.title}>Редактировать категорию</span>
            {languages.map(item => 
                <Field 
                    name={item.key}
                    component={Input} 
                    label={`Наименование (${formatName(item)})`} 
                    validate={item.key === language 
                            ? required({msg: 'Это поле обязательно для заполнения'})
                            : null} />    
            )}

            <div className={classes.adresses}>
                <FormControl component="fieldset" className={classes.formControl}>
                    {!isOneCity && <FormLabel component="legend"><span style={{ color: 'black' }}>Выберите город из списка</span><span style={{ color: 'red' }}>*</span></FormLabel>}

                    {!isOneCity && 
                        <Select 
                            changeSelectOpen={changeSelectOpen}
                            changeSelectClose={changeSelectClose}
                            selectedCities={selectedCities} 
                            setSelectedCities={setSelectedCities} />}

                    <FormGroup
                        className={classes.formControlLabel}
                    >
                        {!isOneTerminal && boxState && selectedTerminals && <FormLabel className={classes.checkTerminals} component="legend"><span style={{ color: 'black' }}>Выбор заведения</span></FormLabel>}
                        {!isOneTerminal && boxState && selectedTerminals.map(item => 
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color={'primary'}  
                                        key={item.name} 
                                        checked={boxState[item.id]} 
                                        onChange={handleChange} 
                                        name={item.id} 
                                    />
                                }
                                label={item.address}
                            />    
                        )}
                    </FormGroup>
                </FormControl>
            </div>
            <Button 
                className={classes.button} 
                fontSize={22} 
                color={'#fff'} 
                load={load}
                disabled={!selectedCities.length > 0}
                text="Сохранить"
                handleClick={handleSubmit} />
        </form>
    )
}

const afterSubmit = (result, dispatch) =>
  dispatch(reset('editCategory'));

EditCategoryForm = reduxForm({form: 'editCategory', onSubmitSuccess: afterSubmit})(EditCategoryForm);

export default EditCategoryForm;