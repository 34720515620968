import React, { useEffect } from 'react';
import {reduxForm, reset, Field} from 'redux-form';
import { Link } from 'react-router-dom';
import {Input} from '../../ui/Input';
import {required} from 'redux-form-validators';
import Button from '../../../../components/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '../ui/Select';

import useStyles from './styles';

let AddCategoryForm = (props) => {
    const {
        load,
        language,
        languages,
        isCheckBoxList,
        isSelectOpen,
        selectedTerminals,
        setSelectedTerminals,
        boxState,
        handleSubmit,
        handleChange,
        changeSelectOpen,
        changeSelectClose,
        selectedCities,
        setSelectedCities,
        cities,
        terminals,
    } = props;

    const classes = useStyles();
    const isOneCity = cities.length === 1;
    const isOneTerminal = selectedTerminals.length === 1;

    useEffect(() => {
        if (isOneCity && selectedCities.length === 0) setSelectedCities([cities[0].address]);
    }, [isOneCity]);

    useEffect(() => {
        if (isOneCity && selectedTerminals.length === 0) {
            setSelectedTerminals(terminals);
        }

    }, [selectedTerminals, isOneCity]);

    console.log('selectedTerminals', selectedTerminals);

    return (
        <form className={classes.formContainer}  autoComplete='off' onSubmit={handleSubmit}>
            <Link to={'/page/menu/products/'} className={classes.close}>
                <ArrowBackIcon className={classes.closeIcon} />
            </Link>
            <span className={classes.title}>Добавить категорию</span>
            {languages.map(item => {
                let name = item.label.split(' ')[2];
                name = name.replace('ом', 'ий');
                name = name.charAt(0).toUpperCase() + name.slice(1);
                return (
                    <Field name={item.key}
                        component={Input} label={`Наименование (${name})`} 
                        validate={item.key === language ? required({msg: 'Это поле обязательно для заполнения'}): null}
                    />
                )
            })}

            <div className={classes.adresses}>
                <FormControl component="fieldset" className={classes.formControl}>
                    {!isOneCity && <FormLabel component="legend"><span style={{ color: 'black' }}>Выберите город из списка </span><span style={{ color: 'red' }}>*</span></FormLabel>}

                    {!isOneCity && <Select 
                        cities={cities}
                        changeSelectOpen={changeSelectOpen}
                        changeSelectClose={changeSelectClose}
                        selectedCities={selectedCities} 
                        setSelectedCities={setSelectedCities} />}

                    <FormGroup
                        className={classes.formControlLabel}
                    >
                        {!isOneTerminal && boxState && selectedTerminals && <FormLabel className={classes.checkTerminals} component="legend"><span style={{ color: 'black' }}>Выбор заведения</span></FormLabel>}
                        {!isOneTerminal && boxState && selectedTerminals.map(item => 
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color={'primary'}  
                                        key={item.name} 
                                        checked={boxState[item.id]} 
                                        onChange={handleChange} 
                                        name={item.id} 
                                    />
                                }
                                label={item.address}
                            />    
                        )}
                    </FormGroup>
                </FormControl>
            </div>
            <Button 
                className={classes.button} 
                fontSize={22} 
                color={'#fff'} 
                load={load}
                disabled={props.pristine || props.submitting || !selectedCities.length > 0}
                text="Добавить"
                handleClick={handleSubmit} />
        </form>
    )
}

const afterSubmit = (result, dispatch) =>
  dispatch(reset('addCategory'));

AddCategoryForm = reduxForm({form: 'addCategory', onSubmitSuccess: afterSubmit})(AddCategoryForm);

export default AddCategoryForm;