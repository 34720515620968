const ADD_SHOPS = 'ADD-SHOPS';
const ADD_CATEGORY = 'ADD-CATEGORY';
const EDIT_CATEGORY = 'EDIT-CATEGORY';
const UPDATE_KEY_SHOP = 'UPDATE-KEY-SHOP';
const EDIT_SHOP = 'UPDATE-SHOP';
const UPDATE_CATEGORIES = 'UPDATE-CATEGORIES';
const UPDATE_CATEGORIES_ORDER = 'UPDATE-CATEGORIES-ORDER';
const EDIT_PRODUCT = 'EDIT-PRODUCT';
const UPDATE_PRODUCT = 'UPDATE-PRODUCT';
const UPDATE_PRODUCTS = 'UPDATE-PRODUCTS';
const UPDATE_LANGUAGES = 'UPDATE-LANGUAGES';
const UPDATE_DEFAULT_LANGUAGE = 'UPDATE-DEFAULT-LANGUAGE';
const UPDATE_SETTINGS = 'UPDATE-SETTINGS';
const UPDATE_DELIVERY = 'UPDATE-DELIVERY';
const UPDATE_WORKTIME = 'UPDATE-WORKTIME';
const UPDATE_WORKHOURS = 'UPDATE_WORKHOURS';
const UPDATE_DEFAULT_HOURS = 'UPDATE_DEFAULT_HOURS';
const UPDATE_VISIBILITY = 'UPDATE-VISIBILITY';
const UPDATE_PAYCARD = 'UPDATE-PAYCARD';
const UPDATE_ISDIRTY_WORKTIME = 'UPDATE_ISDIRTY_WORKTIME';
const UPDATE_DISCOUNT = 'UPDATE-DISCOUNT';
const UPDATE_WORKING_HOURS = 'UPDATE-WORKING-HOURS';

let initialState = {
   activeShop: null,
   defaultLanguages: '',
   shops: [],
   category: [],
   categories: [],
   languages: [],
   products: [],
   appSettings: {},
   keyShop: null,
   hiddenCategories: [],
   hiddenProducts: [],
   workingHours: {},
}

const shopsReducer = (state = initialState, action) => {
    switch(action.type){
        case ADD_SHOPS: {
            return {
                ...state,
                shops: action.data.shops,
                appSettings: action.data.appSettings,
                category: action.data.category,
                hiddenCategories: action.data.category && action.data.category.hiddenCategories ? action.data.category.hiddenCategories : [],
                hiddenProducts: action.data.category && action.data.category.hiddenProducts ? action.data.category.hiddenProducts : [],
            }
        }
        case EDIT_SHOP: {
            let newStateShop = [...state.shops];
            newStateShop[action.data.key][action.data.params] = action.data.value;
            
            return {
                ...state,
                shops: newStateShop
            }
        }
        case UPDATE_VISIBILITY: {
            return {
                ...state,
                [action.data.params]: action.data.list
            }
        }
        case UPDATE_PAYCARD: {
            let {id, names, list} = action.data;

            for (let index in names) {
                state.shops[id].cardPaymentSystem[names[index]] = list[names[index]];
            }
            return state;
        }
        case UPDATE_KEY_SHOP: {
            return {
                ...state,
                keyShop: action.key
            }
        }
        case UPDATE_DELIVERY: {
            return {
                ...state,
                shops: [
                ...state.shops,
                state.shops[action.data.key] = {
                    ...state.shops[action.data.key],
                    delivery: action.data.delivery,
                    deliveryFreeLimit: action.data.deliveryFreeLimit,
                    deliveryLimit: action.data.deliveryLimit
                }
                ]
            }
        }
        case UPDATE_WORKTIME: {
            return {
                ...state,
                shops: [
                   ...state.shops,
                   state.shops[action.data.key].workingHours = {
                       ...state.shops[action.data.key].workingHours,
                       begin: action.data.begin,
                       end: action.data.end
                   }
                ]
            }
        }
        case UPDATE_WORKHOURS: {
            return {
                ...state,
                shops: action.data
            }
        }
        case UPDATE_DEFAULT_HOURS: {
            return {
                ...state,
                shops: action.data
            }
        }
        case UPDATE_SETTINGS: {
            return {
                ...state,
                appSettings: {
                    ...state.appSettings,
                    [action.data.params]: action.data.value
                }
            }
        }
        case UPDATE_ISDIRTY_WORKTIME: {
            return {
                ...state,
                isDirtyWorktime: action.data
            }
        }
        case ADD_CATEGORY: {
            return {
                ...state,
                categories: {
                    ...state.categories,
                    ...action.data,
                }
            }
        }
        case EDIT_CATEGORY: {
            return {
                ...state,
                categories: {
                    ...state.categories,
                    [action.data.id]: action.data.category,
                }
            }
        }
        case UPDATE_CATEGORIES: {
            return {
                ...state,
                categories: action.data,
            }
        }
        case UPDATE_CATEGORIES_ORDER: {
            return {
                ...state,
                settings: {
                    ...state.settings,
                    Category: {
                        ...state.settings.Category,
                        categoriesOrder: action.data
                    }
                }
            }
        }
        case EDIT_PRODUCT: {
            return {
                ...state,
                products: {
                    ...state.products,
                    [action.data.id]: {
                        ...state.products[action.data.id],
                        ...action.data.product
                    },
                },
            }
        }
        case UPDATE_PRODUCT: {
            return {
                ...state,
                products: {
                    ...state.products,
                    [action.data.id]: action.data.product
                },
            }
        }
        case UPDATE_PRODUCTS: {
            return {
                ...state,
                products: action.data,
            }
        }
        case UPDATE_LANGUAGES: {
            return {
                ...state,
                languages: action.data,
            }
        }
        case UPDATE_DEFAULT_LANGUAGE: {
            return {
                ...state,
                defaultLanguage: action.data,
            }
        }
        case UPDATE_DISCOUNT: {
            return {
                ...state,
                appSettings: {
                    ...state.appSettings,
                    [action.data.name]: action.data.value
                }
            }
        }
        case UPDATE_WORKING_HOURS: {
            return {
                ...state,
                workingHours: action.workingHours
            }
        }
        default:
           return state;
    }
}

export const addShops = (dispatch, data) => dispatch({type: ADD_SHOPS, data});
export const addCategory = (dispatch, data) => dispatch({type: ADD_CATEGORY, data});
export const editCategory = (dispatch, data) => dispatch({type: EDIT_CATEGORY, data});
export const updateKeyShop = (key) => ({type: UPDATE_KEY_SHOP, key});
export const updateKeyShopDispatch = (dispatch, key) => dispatch({type: UPDATE_KEY_SHOP, key});
export const editShops = (data) => ({type: EDIT_SHOP, data});
export const updateSettings = (data) => ({type: UPDATE_SETTINGS, data});
export const updateWorktime = (data) => ({type: UPDATE_WORKTIME, data});
export const updateWorkhours = (data) => ({type: UPDATE_WORKHOURS, data});
export const updateDefaultHours = (data) => ({type: UPDATE_DEFAULT_HOURS, data});
export const updateDelivery = (data) => ({type: UPDATE_DELIVERY, data});
export const updateVisibility = (data) => ({type: UPDATE_VISIBILITY, data});
export const updatePayCard = (data) => ({type: UPDATE_PAYCARD, data});
export const updateIsDirtyWorktime = (data) => ({type: UPDATE_ISDIRTY_WORKTIME, data});
export const updateCategories = (data) => ({type: UPDATE_CATEGORIES, data});
export const updateCategoriesDispatch = (dispatch, data) => dispatch({type: UPDATE_CATEGORIES, data});
export const updateProduct = (dispatch, data) => dispatch({type: UPDATE_PRODUCT, data});
export const updateProductsDispatch = (dispatch, data) => dispatch({type: UPDATE_PRODUCTS, data});
export const updateCategoriesOrder = (data) => ({type: UPDATE_CATEGORIES_ORDER, data});
export const editProduct = (dispatch, data) => dispatch({type: EDIT_PRODUCT, data});
export const updateProducts = (data) => ({type: UPDATE_PRODUCTS, data});
export const updateLanguages = (dispatch, data) => dispatch({type: UPDATE_LANGUAGES, data});
export const updateDefaultLanguage = (dispatch, data) => dispatch({type: UPDATE_DEFAULT_LANGUAGE, data});
export const updateDiscount = (dispatch, data) => dispatch({type: UPDATE_DISCOUNT, data});
export const updateWorkingHours = (workingHours) => ({type: UPDATE_WORKING_HOURS, workingHours});

export default shopsReducer;