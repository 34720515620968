import React, { useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {required} from 'redux-form-validators';
import { Field, reduxForm } from 'redux-form';

import Button from '../../../../components/Button';
import Loading from '../../../../components/Loading';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import CustomizedInputs from '../../../../components/UIform';
import useStyles from './styles';

const ProductsForm = (props) => {
    const { handleSubmit } = props;
    const classes = useStyles();
    const [isDelete, setIsDelete] = useState(null);
    const [products, setProducts] = useState(props.products);
    const isMin600 = useMediaQuery('(max-width: 599px)');
    const isMax600 = useMediaQuery('(min-width: 600px)');

    let hiddenProducts = [];

    if (props.hiddenProducts) {
      hiddenProducts = [...props.hiddenProducts];
    }
    
      const handleDelete = (chipToDelete) => () => {
        let list = hiddenProducts.filter((chip) => chip !== chipToDelete);
        let product = props.products[chipToDelete];
        // if (product && product.spots) {
        //   product.spots[0].visible = "1";
        // } 

        setIsDelete(true);
        setProducts({
          ...products,
          [chipToDelete]: {
            ...product
          }
        });

        props.updateVisibility(list, 'hiddenProducts');
      };

      const addChip = (data) => {
        // console.log('props', props)
        let status = hiddenProducts.find((chip) => chip === data.id);
        let product = props.products[data.id];
        if (product && product.spots) {
          product.spots[0].visible = "0";
        } 
        
        setIsDelete(false);
        setProducts({
          ...products,
          [data.id]: {
            ...product
          }
        });

        if(status === undefined){
          let list = [...hiddenProducts, data.id];
            props.updateVisibility(list, 'hiddenProducts');
            props.reset();
        } else {
            alert('Данный ID уже есть в списке')
        }
      };

      const saveData = () => {
        props.saveData('hiddenProducts');

        if (products && isDelete) {
          props.changeVisibleProduct(products);
        }

        if (products && !isDelete) {
          props.changeVisibleProduct(products);
        }
      }

    return(
    <div className={classes.productsWrapper}>
     <Typography variant="h6" className={classes.title}>Товары</Typography>
     <div className={classes.container}>
     <div className={classes.row}>
     <div className={classes.tagContainer}>
     {hiddenProducts.map((data, index) => {
        return (
          <li key={index}>
            <Chip
              label={data}
              onDelete={handleDelete(data)}
              className={classes.chip}
            />
             {isMin600 && <hr className={classes.hr} />}
            <hr className={classes.hr} />
          </li>
        );
      })}
       </div>
    <form onSubmit={handleSubmit} className={classes.formContainer} autoComplete='off'>
        <Field  name="id"
                className={classes.input}
                component={CustomizedInputs}
                type="text"
                placeholder="Введите ID товара"
                rows={'1'}
                disabled={props.loadStatus ? true : false}
                validate={required({msg: 'Это поле обязательно для заполнения'})}/>

            <Button text={props.loadStatus ? 
                <Loading size={22} color={'#fff'}/> : 'Добавить в список'} 
                            disabled={props.loadStatus ? true : false}
                             handleClick={handleSubmit(addChip)}
                             className={classes.addToList} />
            <div className={classes.bt}>
            <Button text={props.loadStatus ? 
                <Loading size={22} color={'#fff'}/> : 'Сохранить'} 
                            disabled={props.loadStatus ? true : false}
                             handleClick={saveData} 
                             className={classes.saveButton} />
            </div>
    </form>
    {props.isActiveMenusSnackbar && 
            <SnackbarContent className={classes.snackbar} message="Данные успешно сохранены!" />}
    </div>
    </div>
    </div>
    )
}

export default reduxForm({
    form: 'ProductsForm', 
  })(ProductsForm);