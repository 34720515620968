import React, { useEffect }  from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import { SORT_MODE_PRODUCTS } from '../../../../constants/routes';

import { formatPathname } from '../other';

const ListCategories = (props) => {
    const { history, location } = props;
    const classes = useStyles();
    const categories = useSelector(state => state.shops.categories);
    const firstCategory = Object.keys(categories)[0];
    let url = location.pathname;

    useEffect(() => {
        if (url === SORT_MODE_PRODUCTS) {
            history.push(`/page/menu/products/sort-mode/products/category${firstCategory}`);  
        }
    }, [location.pathname, firstCategory]);
    
    return(
        <Router>
            <div className={classes.listCategoriesWrapper}>
                <nav className={classes.navigation}>
                    <ul className={classes.listCategories}>
                        {props.categories.map((item, index) => {
                            let pathname = history.location.pathname;
                            let isCategory = pathname.indexOf('category');

                            if (isCategory !== -1) {
                                pathname = formatPathname(pathname);
                            }

                            let flag = isCategory !== -1 ? 'category' : '/category';
                            let url = pathname + flag + item.id;

                            return !item.open ? 
                                <NavLink to={url} onClick={() => history.push(url)} className={classes.category} key={index}>
                                    {item.name}
                                </NavLink> : null;
                         })}
                    </ul>
                </nav>
            </div>
        </Router>
    )
}

const useStyles = makeStyles({
    navigation: {
        width: 'calc(100vw - 300px)',
        overflowX: 'auto',
    },
    listCategories: {
        display: 'flex',
        alignItems: 'center',
        margin: 0,
        padding: '10px 0'
    },
    category: {
        display: 'flex',
        padding: '10px 25px',
        display: 'flex',
        boxShadow: '0px -1px 14px rgba(0, 0, 0, 0.09)',
        borderRadius: 8,
        cursor: 'pointer',
        margin: '0px 10px',
        fontWeight: 600,
        textDecoration: 'none',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        '&.active': {
            backgroundColor: '#326DFF',
            color: 'white',
        }
    },
    activeCategory: {
        background: '#326dff',
        color: '#fff',
    }
});

export default withRouter(ListCategories);