import React, { useState, useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import { withFirebase } from '../../../../firebase';
import { withRouter } from 'react-router-dom';

import { reorder } from '../other';
import { updateCategories, updateCategoriesOrder } from '../../../../reducers/shops';
import SortCategories from './sortCategories';

const SortModeProducts = (props) => {
    const login = useSelector(state => state.auth.login);
    const categories = useSelector(state => state.shops.categories);
    const language = useSelector(state => state.shops.defaultLanguage);

    const [openModal, setOpenModal] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [submitData, setSubmitData] = useState(null);
    const [categoriesList, setCategoriesList] = useState(null);
    const [categoriesOrder, setCategoriesOrder] = useState(null);

    const handleClose = () => {
        setOpenModal(false);
        setIsSubmit(false);
    };

    const handleClickSubmit = () => {
        setOpenModal(false);
        setIsSubmit(true);
    };

    const getCategoriesList = () => {
        let data = [];
        let listData = {};

        for (let id in categories) {
            if (categoriesOrder) {
                for (let key in categoriesOrder) {
                    if (categoriesOrder[key] !== id) {
                      listData[id] = categories[id]
                    }
                  }
            } else {
                listData[id] = categories[id]
            }
        }

        for (let key in listData) {
            let item = { id: key, name: categories[key].name[language] };
            data.push(item);
        }

        if (data.length > 0) setCategoriesList(data);

    }

    const sortCategories = (ids) => {
        const categoriesIds = [ ...ids ];
        let data = {};

        if (categoriesOrder) {
            let categoriesOrders = [];
            const categoriesOrderIds = Object.values(categoriesOrder);

            categoriesIds.map(id => {
            categoriesOrderIds.map(orderId => {
                if (orderId === id) {
                categoriesOrders.push(id);
                }
            });
            });

            for (let key in categoriesOrders) {
            if (categories[categoriesOrder[key]]) {
                data[categoriesOrder[key]] = categories[categoriesOrder[key]]
            }
            }
        }

        console.log('data --->>>', data);

        if (data) props.updateCategories(data);
    }

    useEffect(() => {
        if (!categoriesOrder) {
            props.firebase.bd.ref(`${login === 'vashlavash' ? '' : login}/settings/Category/categoriesOrder`).once("value", (snapshot) => {
                let data = snapshot.val();
        
                if (data) setCategoriesOrder(data);
            });
        }
    }, [categoriesOrder]);

    useEffect(() => {
        if (isSubmit) {
            handleSubmit(submitData);
            setOpenModal(false);
        };
    }, [isSubmit]);

    useEffect(() => {
        if (!categoriesList && categories && categoriesOrder) getCategoriesList();
        if (!categoriesList && categories && !categoriesOrder) getCategoriesList();
    }, [categoriesList, categories, categoriesOrder]);

    const onDragEnd = (result) => {
        if (!result.destination) {
          return;
        }

        let data = reorder(
          categoriesList,
          result.source.index,
          result.destination.index,
        );

        let sortData = [];
        data.map((item) => sortData.push(item.id));

        setCategoriesList(data);
        setSubmitData(sortData);

        // console.log('data', data);
    }

    const clearStateByProducts = () => {
        setIsSubmit(false);
        setSubmitData(null);
        setCategoriesOrder(null);
        // setCategoriesList(null);
    };

    const sendToUpdateFirebase = (data) => {
        props.firebase.bd.ref(`${login === 'vashlavash' ? '' : login}/settings/Category/categoriesOrder`).set(data)
            .then(res => {
                clearStateByProducts();
                // sortCategories(data);
                // updateCategoriesOrder(categoriesList);
            })
            .catch(error => {
                console.error(error);
            });
    }

    const handleSubmit = (data) => {
        console.log('submit data', data);

        if (!data) {
            clearStateByProducts();
        } else {
            sendToUpdateFirebase(data);
        }
    }

    console.log('props --- >>>', props);

    return (
        <SortCategories 
            openModal={openModal}
            categoriesList={categoriesList}
            handleClose={handleClose}
            handleClickSubmit={handleClickSubmit}
            onDragEnd={onDragEnd}
            setOpenModal={setOpenModal}
        />
    )
}

export default connect(null, { updateCategories, updateCategoriesOrder })(withRouter(withFirebase(SortModeProducts)));