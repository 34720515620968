import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../../../firebase';
import { useSelector, useDispatch } from 'react-redux';
import { addCategory, updateCategoriesOrder } from '../../../../reducers/shops';

import AddCategoryForm from './form';
import useStyles from './styles';

let Categories = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    let categoriesOrder = useSelector(state => state.shops.category.categoriesOrder);
    const login = useSelector(state => state.auth.login);
    const cities = useSelector(state => state.shops.shops);
    const language = useSelector(state => state.shops.defaultLanguage);
    const languages = useSelector(state => state.shops.languages);

    const [isSelectOpen, setIsSelectOpen] = useState(null);
    const [preloder, setPreloader] = useState(false);
    const [boxState, setBoxState] = useState(null);
    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedTerminals, setSelectedTerminals] = useState([]);
    const isCheckBoxList = boxState && Object.keys(boxState).length === selectedTerminals.length;

    console.log('state', state);

    // useEffect(() => {
    //     // setTimeout(() => alert('Необходимо выбрать город!'), 1500);
    // }, []);

    const changeSelectClose = () =>  setIsSelectOpen(false);
    const changeSelectOpen = () => setIsSelectOpen(true);

    const handleChange = (event) => {
        let boxStates = { ...boxState };
        boxStates[event.target.name] = !boxState[event.target.name];

        setBoxState(boxStates);
    };

    const getTerminals = () => {
        let terminals = [];

        selectedCities.map(address => {
            const { spot_id, deliveryTerminals } = cities.find(city => city.address === address);

            deliveryTerminals.map((elem, index) => { 
                deliveryTerminals[index] = { ...elem, cityId: spot_id };
            });

            terminals.push(...deliveryTerminals);
        });

        return terminals;
    }

    const getBoxState = () => {
        let boxStates = {};
        selectedTerminals.map(item => boxStates[item.id] = item.active);

        setBoxState(boxStates);
    }

    const changeTerminalsFromBoxState = (terminals) => {
        const boxStateLength = Object.keys(boxState).length;
        if (boxStateLength !== terminals.length) {
            let boxStates = {};

            terminals.map(item => {
                boxStates[item.id] = item.active
            });

            setBoxState(boxStates);
        }
    }

    // useEffect(() => {
    //     if (cities) {
    //         const terminals = getTerminals();

    //         console.log('terminals', terminals)
    //     }
    // }, [cities]);

    useEffect(() => {
        const terminals = getTerminals();

        if (isSelectOpen === false) {
            setSelectedTerminals(terminals);
            if (boxState) changeTerminalsFromBoxState(terminals);  
            setIsSelectOpen(null);
        }

    }, [isSelectOpen, boxState]);

    useEffect(() => {
        const isDefault = !boxState && selectedTerminals.length > 0;

        if (isDefault) getBoxState();
        // if (boxState) changeTerminalsFromBoxState();    

    }, [boxState, selectedTerminals]);

    const createNewCategory = (data) => {
        let spots = {};
        let allTerminals = [];

        cities.map(city => allTerminals.push(...city.deliveryTerminals));

        allTerminals.map((terminal, index) => {
            const spot = {
                spot_id: terminal.id,
                visible: "0",
            };

            spots[index] = spot;

            for (let key in boxState) {
                if (terminal.id === key) {
                    const spot = {
                        spot_id: terminal.id,
                        visible: boxState[key] ? "1" : "0",
                    };
        
                    spots[index] = spot;
                }
            }

        });

        return {
            icon: "default",
            spots,
            name: {
                ru: data.ru ? data.ru : null,
                uk: data.uk ? data.uk : null,
                en: data.en ? data.en : null,
            },
        };
    }

    const onSubmit = (data) => {
        // console.log('--- Submit data ---', data);
        const category = createNewCategory(data);

        console.log('category', category);

        setPreloader(true);
        props.firebase.bd.ref(`${login === 'vashlavash' ? '' : login}/categories`).push(category)
            .then(res => {
                let data = { 
                    [res.key]: { 
                        ...category,
                        id: res.key,
                    } 
                };

                console.log('res', res);

                categoriesOrder = categoriesOrder ? categoriesOrder : [];
                categoriesOrder.push(res.key);

                // console.log('categoriesOrder', categoriesOrder);

                console.log('categoriesOrder', categoriesOrder);

                props.firebase.bd.ref(`${login === 'vashlavash' ? '' : login}/settings/Category/categoriesOrder`).set(categoriesOrder)
                    .then(res => {
                        addCategory(dispatch, data);
                        setPreloader(false);
                        updateCategoriesOrder(categoriesOrder);
                        
                        props.history.push('/page/menu/products/');
                    })
                    .catch(error => {
                        console.error(error);
                    });
            })
            .catch(error => {
                console.error(error);
                setPreloader(false);
            });
    }

    // console.log('isSelectOpen', isSelectOpen);
    // console.log('selectedTerminals', selectedTerminals);
    // console.log('boxState', boxState);
    // console.log('state', state);

    return(
        <div className={classes.root}>
            <div className={classes.modal}>
               <AddCategoryForm
                    load={preloder} 
                    language={language}
                    languages={languages}
                    cities={cities}
                    terminals={getTerminals()}
                    selectedTerminals={selectedTerminals}
                    setSelectedTerminals={setSelectedTerminals}
                    boxState={boxState}
                    isSelectOpen={isSelectOpen}
                    onSubmit={onSubmit}
                    handleChange={handleChange}
                    changeSelectClose={changeSelectClose}
                    changeSelectOpen={changeSelectOpen}
                    selectedCities={selectedCities}
                    setSelectedCities={setSelectedCities}
               />
            </div>
        </div>
    )
}

export default withFirebase(withRouter(Categories));