import React, { Component } from 'react';

import Push from './push';
import { withFirebase } from '../../firebase';
import AlertComponent from '../../components/AlertComponent';
import {connect} from 'react-redux';

class PushContainer extends Component{

    constructor(){
        super();
        this.state = {
            loadStatus: false,
            phone: [],
            key: null,
            alertText: 'tres',
            alertShow: false,
            alertType: 'error',
            productPush: false, 
        }
    }

    componentDidMount() {
        this.getProductPushKey();
    }

    getProductPushKey = () => {
        this.setState({ loadStatus: true });
        this.props.firebase.bd.ref(`${this.props.login === 'vashlavash' ? '' : this.props.login}/adminPermission/productPush`).on("value", (snapshot) => {
            let productPush = snapshot.val();

            this.setState({ productPush, loadStatus: false });
        });
    }

    sendPush = (data) => {
        const {cityId, phone, audience, text, product} = data;
        let topic = '/topics/';

        let self = this;

        let keyPush = this.props.notificationKey;

        let notification = {
            body: text,
        }

        this.setState({
            loadStatus: this.state.loadStatus ? false : true,
        })

        if (audience === 'favorite') {
            topic += phone.replace(/-/g, '');
        } else if (audience === 'city') {
            topic += cityId;
        } else if (audience === 'all') {
            topic += 'all';
        } else {
            topic += 'all';
        }
 
        let body = {
            to: topic,
            notification,
        }

        if (product) {
            body = {
                ...body,
                notification: {
                    ...notification,
                    click_action: `${this.props.bundleId}.FIREBASE_DATA`,
                    title: '',
                },
                data: {
                    product_id: product,
                    body: '',
                    title: '',
                }
            }
        }

        // console.log('body --- >>>', body);

        // console.log('body JSON =>', JSON.stringify(body));
        // console.log('Authorization', 'key=' + keyPush);

        fetch('https://fcm.googleapis.com/fcm/send', {
            'method': 'POST',
            'headers': {
                'Authorization': 'key=' + keyPush,
                'Content-Type': 'application/json'
         },
            'body': JSON.stringify(body)
        }).then(function(response) {
            self.setState({
                alertText: 'Ваше уведомление успешно отправлено!',
                alertShow: true,
                alertType: 'success',
                loadStatus: false
            })
        }).catch(function(error) {
            self.setState({
                alertText: 'При отправке произошла ошибка, попробуйте еще раз!',
                alertShow: true,
                alertType: 'error', 
                loadStatus: false
            })
        });
 
    }

    addPhone = (data) => {
        let phoneList = this.state.phone;
        phoneList.push(data.phone);
        this.setState({
            phone: phoneList,
        })
    }

    deletePhone = (key) => {
        let phoneList = this.state.phone;
        phoneList.splice(key, 1);
        this.setState({
            phone: phoneList
        })
    }

    hideAlert = () => {
        this.setState({
            alertShow: false
        })
    }

    render() {
        return(
            <>
            <Push onSubmit={this.sendPush}
                  productPush={this.state.productPush}
                  loadStatus={this.state.loadStatus}
                  phone={this.state.phone}
                  addPhone={this.addPhone}
                  deletePhone={this.deletePhone}/>
                  {this.state.alert}
            <AlertComponent type={this.state.alertType} 
                            text={this.state.alertText} 
                            status={this.state.alertShow}
                            hideAlert={this.hideAlert}
                            />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.auth.login,
        notificationKey: state.shops.appSettings.notificationKey,
        bundleId: state.shops.appSettings.bundleId
    }
}

export default connect(mapStateToProps)(withFirebase(PushContainer));