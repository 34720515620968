import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Switch from '../../components/Switch';

const TapApp = (props) => {
    const classes = useStyle();
    return (
        <div className={classes.container}>
            <div className={classes.checkBoxContainer}>
                <div className={classes.switchContainer}>
                    {props.tab === 2 && props.isActiveApps && (
                        <>
                            <Typography className={classes.typography}>Только негативные</Typography>
                            <Switch 
                                className={classes.switchComponent} 
                                active={true}
                                changeChecker={() => console.log('check')} /> 
                        </>
                    )}
                </div>
                <div className={classes.switchContainer}>
                    <Typography className={classes.typography}>Сортировать</Typography>
                    <FormControl className={classes.selectContainer}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.typeSort}
                            onChange={(e) => props.sort(e.target.value)}
                            className={classes.select}
                        >
                        <MenuItem value={'newest'}>Самые новые</MenuItem>
                        <MenuItem value={'highGrade'}>Самая высокая оценка</MenuItem>
                        <MenuItem value={'lowGrade'}>Самая низкая оценка</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    )
}

const useStyle = makeStyles(theme => ({
    container: {
        margin: '16px 0 24px 0',
        height: 40,
        backgroundColor: 'rgba(241,243,244, .5)',
        border: '1px solid #F1F3F4',
        boxSizing: 'border-box',
        borderRadius: 5,
        [theme.breakpoints.down("600")]: {
            height: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        }
    },
    typography: {
        fontSize: 14,
        lineHeight: '16px',
        color: '#476282',
    },
    checkBoxContainer: {
        margin: '0 16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        lineHeight: 40,
        height: 40,
        [theme.breakpoints.down("600")]: {
            width: '100%',
            height: 'auto',
            lineHeight: 'inherit',
            flexDirection: 'column',
            justifyContent: 'space-between',
        }
    },
    switchContainer: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down("600")]: {
            width: '100%',
            height: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
        }
    },
    selectContainer: {
        maxwWidth: 150,
        marginLeft: 5,
        '& .MuiInput-underline:before': {
           display: 'none',
          },
        '& .MuiInput-underline:after': {
            display: 'none',
        },
        '& .MuiSelect-select': {
            paddingTop: 0,
            paddingBottom: 0,
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: '#fafafa'
        },
      },
      select: {
        fontSize: 14,
        padding: 0,
      },
}));

export default TapApp;