import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withFirebase } from '../../../firebase';
import EditProducts from './editProducts';
import Preloader from '../preloader/preloader';
import axios from 'axios';
import {updateVisibility} from '../../../reducers/shops';

class EditProductsContainer extends Component{

    constructor(){
        super();

        this.state = {
            cities: [],
            categories: [],
            activeCategory: null,
            products: [],
            defaultHiddenProducts: null,
            languageModal: false,
            languages: [],
            language: '',
            defaultLanguage: '',
            editModal: false,
            editId: null,
            preloader: false
        }

    }

    componentDidMount() {
        this.getLanguages();
        setTimeout(() => this.getCategories(), 300)
        this.getDefaultHiddenProducts();
        // this.updateCategory();
    }

    // getCities = () => {
    //     this.setState({ preloader: true });
    //     this.props.firebase.bd.ref(`settings/City`).once('value', (snapshot) => {
    //         let cities = snapshot.val();

    //         this.setState({ cities, preloader: false });
    //     });
    // }

    getDefaultHiddenProducts = () => {
        this.setState({ preloader: true });
        this.props.firebase.bd.ref(`${this.props.login === 'vashlavash' ? '' : this.props.login}/settings/Category/hiddenProducts`).once('value', (snapshot) => {
            let defaultHiddenProducts = snapshot.val();

            this.setState({ defaultHiddenProducts, preloader: false });
        });
    }

    getDefaultLanguage = () => {
        this.setState({ preloader: true });
        this.props.firebase.bd.ref(`${this.props.login === 'vashlavash' ? '' : this.props.login}/adminPermission/defaultLanguage`).once('value', (snapshot) => {
            let defaultLanguage = snapshot.val();

            if (defaultLanguage) this.setState({ defaultLanguage, language: defaultLanguage, preloader: false });

            this.setState({ preloader: false });
        });
    }

    getLanguages = () => {
        let defaultLanguage = this.state.defaultLanguage;

        if (!defaultLanguage || defaultLanguage.length === 0) {
            this.setState({ preloader: true });
            this.props.firebase.bd.ref(`${this.props.login === 'vashlavash' ? '' : this.props.login}/adminPermission/defaultLanguage`).once('value', (snapshot) => {
                let val = snapshot.val();

                defaultLanguage = val;

                this.setState({ defaultLanguage, preloader: false });
            });
        }

        let languages = this.props.languages;
            if (!languages || languages.length === 0) {
                this.setState({ preloader: true });
                this.props.firebase.bd.ref(`${this.props.login === 'vashlavash' ? '' : this.props.login}/languages`).once('value', (snapshot) => {
                    let val = snapshot.val();

                    languages = val.sort(function(a, b) {
                        if (defaultLanguage) {
                            if (a.key !== defaultLanguage) {
                                return 1
                            } else {
                                return -1;
                            }
                        }
                    });
                               
                    this.setState({ languages, preloader: false });
                });
            } else {
                languages = languages.sort(function(a, b) {
                    if (defaultLanguage) {
                        if (a.key !== defaultLanguage) {
                            return 1
                        } else {
                            return -1;
                        }
                    }
                });
                this.setState({ languages });
            }
    }

    getCategories = () => {
        let items = this.props.categories;
        let defaultLanguage = this.state.defaultLanguage;

        if (defaultLanguage) {
            if (items.length === 0) {
                this.setState({ preloader: true });
                this.props.firebase.bd.ref(`${this.props.login === 'vashlavash' ? '' : this.props.login}/categories`).once('value', (snapshot) => {
                    let items = snapshot.val();
    
                    let list = [];
                    // let defaultLanguage = this.props.defaultLanguage ? this.props.defaultLanguage : this.state.defaultLanguage;
                    // console.log('defaultLanguage items.length === 0', this.props.defaultLanguage, this.state.defaultLanguage);
                    if (defaultLanguage) {
                        for(let item in items) {
                            list.push({
                                name: items[item].name && items[item].name[defaultLanguage] ? items[item].name[defaultLanguage] : items[item].name[this.state.defaultLanguage],
                                id: item,
                            });
                        }
                        this.setState({ categories: list, preloader: false });
                    }
    
                    // if(list.length > 0) {
                    //     this.updateCategory(list[0].id);
                    // }
                });
            } else {
                let list = [];
                // let defaultLanguage = this.props.defaultLanguage ? this.props.defaultLanguage : this.state.defaultLanguage;
                for(let item in items) {
                    list.push({
                        name: items[item].name && items[item].name[defaultLanguage] ? items[item].name[defaultLanguage] : items[item].name[this.state.defaultLanguage],
                        id: item,
                    });
                }
                // console.log('defaultLanguage', defaultLanguage);
                this.setState({categories: list});
    
                if(list.length > 0) {
                    this.updateCategory(list[0].id);
                }
            }
        }
    }

    hiddenCategory = (data, open) => {
        // console.log('data', data, open);
        
        if (open) {
            console.log('+')
            this.setState({  preloader: true })
            this.props.firebase.bd.ref(`${this.props.login === 'vashlavash' ? '' : this.props.login}/settings/Category/hiddenCategories`)
            .update(data)
            .then(res => {
                this.setState({ preloader: false });
            });
        }

        if (!open) {
            console.log('-')
            this.setState({ preloader: true, hiddenProducts: data });
            this.props.firebase.bd.ref(`${this.props.login === 'vashlavash' ? '' : this.props.login}/settings/Category/hiddenCategories`)
            .set(data)
            .then(res => {
                this.setState({
                    preloader: false
                })
            });
        }
    }

    changeVisibleProduct = (data, id) => {
        console.log('data, id', data, id);

        this.setState({ preloader: true });
        this.props.firebase.bd.ref(`${this.props.login === 'vashlavash' ? '' : this.props.login}/products/${id}`)
        .update(data)
        .then(res => {
            this.setState({
                preloader: false
            })
        });
    }

    hiddenProduct = (data) => {
        console.log('data', data);

        this.setState({ preloader: true, defaultHiddenProducts: data });
        this.props.updateVisibility({list: data, params: 'hiddenProducts'});
        this.props.firebase.bd.ref(`${this.props.login === 'vashlavash' ? '' : this.props.login}/settings/Category/hiddenProducts`)
        .set(data)
        .then(res => {
            this.setState({
                preloader: false
            });
        });
    }

    updateCategory = (id) => {
        let categoryId = Object.keys(this.props.categories)[0];

        let pathId = window.location.pathname.split('/');
        pathId = pathId[pathId.length - 1].split('category')[1];
        pathId = pathId ? pathId : null;

        categoryId = categoryId ? categoryId : pathId;
        id = id ? id : categoryId;

        let self = this;
        if (categoryId) {
            self.setState({ preloader: false });
            self.props.firebase.bd.ref(`${this.props.login === 'vashlavash' ? '' : this.props.login}/products`).orderByChild("menuCategoryId").equalTo(id).once("value", function(snapshot) {
                let items = snapshot.val();

                console.log('items (products) --- >>', items);
    
                let list = [];
                
                for(let item in items) {
                    items[item].id = item;
                    
                    list.push(items[item]);
                }
    
                // console.log('updateCategory list === >>>', list.reverse());
                // console.log('ID === >>>', id);
                self.setState({activeCategory: id, products: list.reverse(), preloader: false});
              });
        }
    }

    statusLanguageModal = () => {
        this.setState({
            languageModal: this.state.languageModal ? false : true
        })
    }

    deleteProduct = (id, name) => {
        if (window.confirm(`Вы хотите удалить товар ${name}?`)) {
            let index = this.state.products.findIndex(x => x.id === id);
        
           this.setState({
                products: this.state.products.filter((_, i) => i !== index)
              });

             this.props.firebase.bd.ref(`${this.props.login === 'vashlavash' ? '' : this.props.login}/products/${id}`).remove();

          }
    };

    statusEditModal = (id) => {
        this.setState({
            editModal: this.state.editModal ? false : true,
            editId: id
        })
    }

    changeActiveCategory = (activeCategory) => {
        this.setState({
            activeCategory,
            preloader: false,
        });
    }

    onSubmit = (data) => {
        console.log('data --- >>> ', data);

        let languagesName = {};
        let languagesDescription = {};

        for (let index in this.state.languages) {
            let key = this.state.languages[index].key;
            let name = 'name_' + key;
            let description = 'description_' + key;

            if (data[name]) {
                languagesName[key] = data[name];
            }

            if (data[description]) {
                languagesDescription[key] = data[description];
            }
        }

        this.setState({preloader: true});

        let self = this;

        let product = this.state.products.find(x => x.id === this.state.editId);
        let index = this.state.products.findIndex(x => x.id === this.state.editId);

        let id = product.id;

        product = {
            ...product,
            name: languagesName,
            description: languagesDescription,
            menuCategoryId: data.categories,
            spots: {
                ...product.spots,
                0: {
                    ...product.spots[0],
                    price: String(Number(data.firstPrice) * 100)  
                },
               
            },
        }

        product.id = null;
        product.open = null;

        if(data.photo){
            let file = new FormData;
            file.append('photo', data.photo[0]);
            file.append('login', this.props.login);
            axios.post(`https://web.e-admin.com.ua/photo/index.php`, file)
            .then(res => {
                const namePhoto = res.data.image_name.split(' ')[0];
                const isSuccess = res.data.check_upload_image;

                if(isSuccess) {
                    product = {
                        ...product,
                        namePhoto: namePhoto,
                        photo: `https://web.e-admin.com.ua/photo/photo/uploads/${this.props.login === 'vashlavash' ? '' : this.props.login}/${namePhoto}`,
                    }
                    self.props.firebase.bd.ref(`${this.props.login === 'vashlavash' ? '' : this.props.login}/products/${id}`).update(product).then(res => {
                        product.id = id;
                        self.state.products[index] = product;
                    }).then(res => {
                        self.statusEditModal();
                        self.setState({preloader: false})
                        alert("Готово");
                    }).catch(res => {
                        console.log('error 1', res);
                        self.setState({preloader: false});
                        alert("Произошла ошибка, попробуйте еще");
                    });
                } else {
                    console.log('error 2', res);
                    alert('Ошибка, попробуйте ещё раз!')
                    self.setState({preloader: false})
                }
            }).catch(res => {
                console.log('error 3', res);
                    alert('Ошибка, попробуйте ещё раз!')
                    self.setState({preloader: false})
            })
            
            } else {
                this.props.firebase.bd.ref(`${this.props.login === 'vashlavash' ? '' : this.props.login}/products/${id}`).update(product).then(res => {
                    product.id = id;
                    self.state.products[index] = product;
                }).then(res => {
                    self.statusEditModal();
                    self.setState({preloader: false})
                    alert("Готово");
                }).catch(res => {
                    self.setState({preloader: false});
                    console.log('error', res);
                    alert("Произошла ошибка, попробуйте еще");
                });
            }

    }

    render() {

        // console.log('state (editProducts) -- >>>', this.state);
        // console.log('loader', this.state.loader);

        return  <>
                    {this.state.preloader 
                        ? <Preloader/> 
                        : <EditProducts     categories={this.state.categories}
                                            changeActiveCategory={this.changeActiveCategory}
                                            cities={this.props.cities}
                                            changeVisibleProduct={this.changeVisibleProduct}
                                            hiddenCategories={this.props.hiddenCategories}
                                            hiddenProducts={this.props.hiddenProducts}
                                            defaultHiddenProducts={this.state.defaultHiddenProducts}
                                            hiddenProduct={this.hiddenProduct}
                                            getCategories={this.getCategories}
                                            activeCategory={this.state.activeCategory}
                                            updateCategory={this.updateCategory}
                                            products={this.state.products}
                                            languageModal={this.state.languageModal}
                                            defaultLanguage={this.props.defaultLanguage}
                                            language={this.props.defaultLanguage}
                                            languages={this.props.languages}
                                            statusLanguageModal={this.statusLanguageModal}
                                            deleteProduct={this.deleteProduct}
                                            editModal={this.state.editModal}
                                            editId={this.state.editId}
                                            statusEditModal={this.statusEditModal}
                                            onSubmit={this.onSubmit}
                                            open={this.props.open} />}
                </>
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.auth.login,
        cities: state.shops.shops,
        categories: state.shops.categories,
        languages: state.shops.languages,
        defaultLanguage: state.shops.defaultLanguage,
        hiddenCategories: state.shops.hiddenCategories,
        hiddenProducts: state.shops.hiddenProducts,
    }
}

export default connect(mapStateToProps, { updateVisibility })(withFirebase(EditProductsContainer));