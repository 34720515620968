import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../../../firebase';
import { useSelector, useDispatch } from 'react-redux';
import { editCategory, updateProductsDispatch } from '../../../../reducers/shops';

import EditCategoryForm from './form';
import useStyles from './styles';

let Categories = (props) => {
    const categoryId = props.match.params.id;
    const classes = useStyles();
    const dispatch = useDispatch();
    const defaultLanguage = useSelector(state => state.shops.defaultLanguage);
    const category = useSelector(state => state.shops.categories[categoryId]);
    const login = useSelector(state => state.auth.login);
    const cities = useSelector(state => state.shops.shops);
    const products = useSelector(state => state.shops.products);
    const language = useSelector(state => state.shops.defaultLanguage);
    const languages = useSelector(state => state.shops.languages);

    const [isSelectOpen, setIsSelectOpen] = useState(null);
    const [preloder, setPreloader] = useState(false);
    const [boxState, setBoxState] = useState(null);
    // const [languages, setLanguages] = useState(null);
    const [sortLanguages, setSortLanguages] = useState(null);
    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedTerminals, setSelectedTerminals] = useState([]);
    const isCheckBoxList = boxState && Object.keys(boxState).length === selectedTerminals.length;

    const changeSelectClose = () =>  setIsSelectOpen(false);
    const changeSelectOpen = () => setIsSelectOpen(true);

    useEffect(() => {
        if (!languages) {
            // getLanguages();
        }

        if (languages && languages.length > 0) {
            sortedLanguages();
        }
    }, [languages]);

    const sortedLanguages = () => {
        languages.sort((a, b) => {
            if (a.key !== defaultLanguage) {
                return 1;
            } else {
                return -1;
            }
        });

        setSortLanguages(languages);
    }

    const handleChange = (event) => {
        let boxStates = { ...boxState };
        boxStates[event.target.name] = !boxState[event.target.name];

        setBoxState(boxStates);
    };

    const getTerminals = () => {
        let terminals = [];

        selectedCities.map(address => {
            const { spot_id, deliveryTerminals } = cities.find(city => city.address === address);

            deliveryTerminals.map((elem, index) => { 
                deliveryTerminals[index] = { ...elem, cityId: spot_id };
            });

            terminals.push(...deliveryTerminals);
        });

        return terminals;
    }

    const getBoxState = () => {
        let boxStates = {};
        let terminals = getTerminals();

        for (let key in terminals) {
            const terminal = terminals[key];
            for (let categoryKey in category.spots) {
                const spot = category.spots[categoryKey];
                const categorySpotId = spot.spot_id ? spot.spot_id : categoryKey;

                if (terminal.id === categorySpotId) {
                    terminals[key] = { ...terminal, visible: spot.visible };
                }
            }
        }

        // terminals.map((terminal, index) => {
        //     for (let key in category.spots) {
        //         const spot = category.spots[key];

        //         if (Number(terminal.id) === Number(spot.spot_id)) {
        //             terminals[index] = { ...terminal, visible: spot.visible };
        //         }
        //     }
        // });

        terminals.map(item => boxStates[item.id] = item.visible === "1");
        
        setBoxState(boxStates);
    }

    const changeTerminalsFromBoxState = (terminals) => {
        const boxStateLength = Object.keys(boxState).length;
        if (boxStateLength !== terminals.length) {
            let boxStates = {};

            terminals.map(item => {
                boxStates[item.id] = item.active
            });

            setBoxState(boxStates);
        }
    }

    useEffect(() => {
            const terminals = getTerminals();

            if (terminals.length > 0 && !selectedTerminals.length) setSelectedTerminals(terminals);
    }, [selectedCities]);

    useEffect(() => {
        if (!boxState && selectedTerminals.length > 0) {
            getBoxState();
        }
    }, [selectedTerminals]);

    useEffect(() => {
        if (category) {
            const terminals = getTerminals();

            if (isSelectOpen === false) {
                setSelectedTerminals(terminals);
                if (boxState) changeTerminalsFromBoxState(terminals);  
                setIsSelectOpen(null);
            }
        }

    }, [isSelectOpen, boxState]);

    // useEffect(() => {
    //     const isDefault = !boxState && selectedTerminals.length > 0;

    //     if (isDefault) getBoxState();
    //     // if (boxState) changeTerminalsFromBoxState();    

    // }, [boxState, selectedTerminals]);

    const createNewCategory = (data) => {
        let spots = [];
        let name = {};
        let allTerminals = [];

        cities.map(city => allTerminals.push(...city.deliveryTerminals));
        const icon = category.icon;
        
        for (let index in sortLanguages) {
            const { key } = sortLanguages[index];

            if (data[key]) {
                name[key] = data[key];
            }

        }

        allTerminals.map((terminal, index) => {
            const spot = {
                spot_id: terminal.id,
                visible: "0",
            };

            spots[index] = spot;

            if (boxState) {
                for (let key in boxState) {
                    if (terminal.id === key) {
    
                        const spot = {
                            spot_id: terminal.id,
                            visible: boxState[key] ? "1" : "0",
                        };
            
                        spots[index] = spot;
                    }
                }
            }

            if (!boxState) {
                const spot = {
                    spot_id: terminal.id,
                    visible: "1",
                };
                spots[index] = spot;
            }

        });

        return {
            icon,
            spots,
            name
        };
    }

    const updateProducts = (categoryId, category) => {
        const result = { ...products };

        for (let key in products) {
            let product = products[key];
            let newProduct = { ...product };
            let spots = [];

            if (categoryId === product.menuCategoryId) {
                for (let c in category.spots) {
                    if (category.spots && product.spots && category.spots.length !== product.spots.length) {
                        // console.log('-- 1 --', product);
                        let productSpots = product.spots.filter(item => !!item);
                        const categorySpot = category.spots[c]; 
                        const productSpot = productSpots[c]; 

                        // console.log('productSpot', productSpot);
                        
                        if (productSpot) {
                            spots.push({ 
                                ...productSpot, 
                                visible: productSpot.price === "0" && productSpot.visible === "0" || productSpot.price !== "0" && productSpot.visible === "0" ? "0" : categorySpot.visible
                            });
                        } else {
                            spots.push({ price: "0", spot_id: categorySpot.spot_id, visible: "0" });
                        }
                    }

                    if (!product || !product.spots || category.spots.length === product.spots.length) {
                        // console.log('-- 2 --', product);
                        let productSpots = product.spots.filter(item => !!item);

                        for (let p in productSpots) {
                            const categorySpot = category.spots[c]; 
                            const productSpot = productSpots[p];
                            
                            if (categorySpot.spot_id === productSpot.spot_id) {
                                spots.push({
                                     ...categorySpot, 
                                     price: productSpot.price, 
                                     visible: productSpot.price === "0" && productSpot.visible === "0" || productSpot.price !== "0" && productSpot.visible === "0" ? "0" : categorySpot.visible
                                });
                            }
                        }
                    }
                }
                newProduct.spots = spots;
                result[key] = newProduct;
                console.log('spots', spots);
            }
        }

        return result;
    }

    const onSubmit = (data) => {
        // console.log('--- Submit data ---', data);
        const category = createNewCategory(data);

        // console.log('category---->>> ',category);
        // console.log('---->>> ', updateProducts(categoryId, category));

        setPreloader(true);
        props.firebase.bd.ref(`${login === 'vashlavash' ? '' : login}/categories/${categoryId}`).update(category)
            .then(res => {
                let data = { id: categoryId, category };

                const productsData = updateProducts(categoryId, category);

                props.firebase.bd.ref(`${login === 'vashlavash' ? '' : login}/products`).update(productsData)
                    .then(res => {
                        // console.log('push to Redux ---> category', data);
                        editCategory(dispatch, data);
                        updateProductsDispatch(dispatch, productsData);
                        setPreloader(false);
                        props.history.push('/page/menu/products/');
                    })
                    .catch(error => {
                        console.error(error);
                    });
            })
            .catch(error => {
                console.error(error);
                setPreloader(false);
            });
    }

    return(
        <div className={classes.root}>
            <div className={classes.modal}>
                <EditCategoryForm
                    load={preloder} 
                    language={language}
                    languages={sortLanguages ? sortLanguages : []}
                    category={category}
                    cities={cities}
                    isCheckBoxList={isCheckBoxList}
                    selectedTerminals={selectedTerminals}
                    boxState={boxState}
                    onSubmit={onSubmit}
                    handleChange={handleChange}
                    changeSelectClose={changeSelectClose}
                    changeSelectOpen={changeSelectOpen}
                    selectedCities={selectedCities}
                    setSelectedCities={setSelectedCities}
                />
            </div>
        </div>
    )
}

export default withFirebase(withRouter(Categories));